@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~vuelayers/lib/style.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

@import 'components/kit/vendors/antd/style';
@import 'components/kit/vendors/bootstrap/style';
@import 'components/kit/vendors/nprogress/style';
@import 'components/kit/vendors/chartist/style';
@import 'components/kit/vendors/chartist-tooltip-plugin/style';
@import 'components/kit/vendors/perfect-scrollbar/style';
@import 'components/kit/vendors/quill/style';

@import 'components/kit/core/style'; // Clean UI KIT styles
@import 'components/cleanui/styles/style'; // Clean UI styles

body {
	text-transform: uppercase !important;
}

.custom-filter-dropdown {
	padding: 8px;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
	background-color: rgb(255, 192, 105);
	padding: 0px;
}

.pointer {
	cursor: pointer !important;
}

.hrText {
	line-height: 1em;
	position: relative;
	outline: 0;
	border: 0;
	color: $cherry;
	text-align: center;
	height: 1.5em;
	opacity: 0.7;
	&:before {
		content: '';
		// use the linear-gradient for the fading effect
		// use a solid background color for a solid bar
		background: linear-gradient(to right, transparent, $cherry, transparent);
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		height: 1px;
	}
	&:after {
		content: attr(data-content);
		position: relative;
		display: inline-block;
		color: $cherry;

		padding: 0 0.5em;
		line-height: 1.5em;
		// this is really the only tricky part, you need to specify the background color of the container element...
		color: $cherry;
		background-color: #fcfcfa;
	}
}

.hrDotted {
	border-top: 1px dashed $gray-6;
}

.ml7 {
	margin-left: 7px !important;
}

.ml10 {
	margin-left: 10px !important;
}

.ml5 {
	margin-left: 15px !important;
}

.m0 {
	margin: 0 !important;
}

.ptb5 {
	padding: 5px 0 !important;
}

.p0 {
	padding: 0 !important;
}

.p10_0 {
	padding: 10px 0 !important;
}

.pt0 {
	padding-top: 0 !important;
}

.pt5 {
	padding-top: 5px !important;
}

.pt10 {
	padding-top: 10px !important;
}

.pt12 {
	padding-top: 12px !important;
}

.pt14 {
	padding-top: 14px !important;
}

.pt15 {
	padding-top: 15px !important;
}

.pt16 {
	padding-top: 16px !important;
}

.pt17 {
	padding-top: 17px !important;
}

.pt20 {
	padding-top: 20px !important;
}

.pt22 {
	padding-top: 22px !important;
}

.pt25 {
	padding-top: 25px !important;
}

.pt35 {
	padding-top: 35px !important;
}

.pt40 {
	padding-top: 40px !important;
}

.fs10 {
	font-size: 10px !important;
}

.fs12 {
	font-size: 12px !important;
}

.fs14 {
	font-size: 14px !important;
}

.fs16 {
	font-size: 16px !important;
}

.fs18 {
	font-size: 18px !important;
}

.fs20 {
	font-size: 20px !important;
}

.fs25{
	font-size: 25px !important;
}

.fs40 {
	font-size: 40px !important;
}

.mt10 {
	margin-top: 10px !important;
}

.mt20 {
	margin-top: 20px !important;
}

.mt23 {
	margin-top: 23px !important;
}

.mt14 {
	margin-top: 14px !important;
}

.mt15 {
	margin-top: 15px !important;
}

.mb20 {
	margin-bottom: 20px !important;
}

.mb10 {
	margin-bottom: 10px !important;
}

.mb0 {
	margin-bottom: 0px !important;
}

.pb0 {
	padding-bottom: 0 !important;
}

.pb20 {
	padding-bottom: 20 !important;
}

.pl10 {
	padding-left: 10px !important;
}

.pl25 {
	padding-left: 25px !important;
}

.plr15 {
	padding-left: 15px !important;
	padding-right: 15px !important;
}

.h60 {
	height: 60px !important;
}

.p10 {
	padding: 10px !important;
}

.m10 {
	margin: 10px !important;
}

.tablePadding25 > tbody > tr > td {
	padding: 5px !important;
	overflow-wrap: break-word;
}

.table-striped {
	background-color: rgba(0, 0, 0, 0.05);
}

.greenTR {
	background-color: #d1e7dd;
}

.smallLabel {
	position: absolute;
    top: 20px;
    left: 0;
    font-size: 12px;
}

.btn-info2 {
	background: #fff3cd !important;
	border: #ffe69c !important; 
	color: #997404 !important;
}

.bg-purple {
	color: #722ed1 !important;
    background: #f9f0ff !important;
}

.btn.btn-purple, .show > .btn.btn-purple, .btn.btn-purple:hover, .btn.btn-purple:active {
    background-color: #f9f0ff !important;
    border-color: #d3adf7 !important;
	color: #722ed1 !important;
}

.btn.btn-rls, .show > .btn.btn-rls, .btn.btn-rls:hover, .btn.btn-rls:active {
    background-color: #e2e3e5 !important;
    border-color: #2b2f32 !important;
	color: #2b2f32 !important;
}

.btn.btn-panel, .show > .btn.btn-panel, .btn.btn-panel:hover, .btn.btn-panel:active {
    background-color: #cff4fc !important;
    border-color: #9eeaf9 !important;
	color: #055160 !important;
}

.bgRls {
	background-color: #e2e3e5;
	color: #2b2f32;
}

.bgRed {
	background-color: #f1aeb5;
}

.bgGreen {
	background-color: #cff4fc;
}

.bgYellow {
	background-color: #fff3cd;
}

.bgSoftGreen {
	background-color: #d1e7dd !important;
    border-color: #a3cfbb !important;
	color:#0a3622 !important;
}

[data-kit-theme=default] .ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
	background: transparent !important;
}

.bgRLS_1 {
	color: #fff !important;
	background-color: #4f999b !important;
}

.bgRLS_2 {
	color: #fff !important;
	background-color: #4f94b7 !important;
}

.bgRLS_3 {
	color: #737373 !important;
	background-color: #f7ceaf !important;
}